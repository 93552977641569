import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    meta:{
      title:'',
    },
    component:() => import('@/views/home.vue')
  },{
    path: "/class",
    name: "class",
    meta:{
      title:'',
    },
    component:() => import('@/views/class.vue')
  },{
    path: "/detail",
    name: "detail",
    meta:{
      title:'',
    },
    component:() => import('@/views/detail.vue')
  },{
    path: "/nationalSpeciality",
    name: "nationalSpeciality",
    meta:{
      title:'',
    },
    component:() => import('@/views/nationalSpeciality.vue')
  },{
    path: "/nationalSpecialityDetail",
    name: "nationalSpecialityDetail",
    meta:{
      title:'',
    },
    component:() => import('@/views/nationalSpecialityDetail.vue')
  },{
    path: "/provincialSpeciality",
    name: "provincialSpeciality",
    meta:{
      title:'',
    },
    component:() => import('@/views/provincialSpeciality.vue')
  },{
    path: "/provincialSpecialityDetail",
    name: "provincialSpecialityDetail",
    meta:{
      title:'',
    },
    component:() => import('@/views/provincialSpecialityDetail.vue')
  },{
    path: "/nationalCourse",
    name: "nationalCourse",
    meta:{
      title:'',
    },
    component:() => import('@/views/nationalCourse.vue')
  },{
    path: "/nationalCourseDetail",
    name: "nationalCourseDetail",
    meta:{
      title:'',
    },
    component:() => import('@/views/nationalCourseDetail.vue')
  },{
    path: "/provincialCourse",
    name: "provincialCourse",
    meta:{
      title:'',
    },
    component:() => import('@/views/provincialCourse.vue')
  },{
    path: "/provincialCourseDetail",
    name: "provincialCourseDetail",
    meta:{
      title:'',
    },
    component:() => import('@/views/provincialCourseDetail.vue')
  },{
    path: "/nationalPilot",
    name: "nationalPilot",
    meta:{
      title:'',
    },
    component:() => import('@/views/nationalPilot.vue')
  },{
    path: "/nationalPilotDetail",
    name: "nationalPilotDetail",
    meta:{
      title:'',
    },
    component:() => import('@/views/nationalPilotDetail.vue')
  },{
    path: "/provincialPilot",
    name: "provincialPilot",
    meta:{
      title:'',
    },
    component:() => import('@/views/provincialPilot.vue')
  },{
    path: "/provincialPilotDetail",
    name: "provincialPilotDetail",
    meta:{
      title:'',
    },
    component:() => import('@/views/provincialPilotDetail.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
