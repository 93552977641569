import axios from "axios";
import { ElMessage } from 'element-plus';
import {commonFunction} from "@/common/commonFunction";

const {Jump} = commonFunction();
//创建axios实例
const request = axios.create({
    //baseURL: "http://server.exhibition.com/api/v1",
    baseURL: "https://exhibition-api.datashopee.com/api/v1",
    timeout: 30000,//超时时间
    headers: {//编译语言
        "Content-type": "application/json"
    },
})
//请求拦截
//就是你请求接口的时候，我会先拦截下来，对你的数据做一个判断，或者携带个token给你
request.interceptors.request.use((config) => {//请求的数据
    //console.log(config)
    config.headers = config.headers || {}  // 没有数据的话就设置为空设置为数据
    //console.log(config.headers)
    if (localStorage.getItem("token")) {//先确保登录
        config.headers.Authorization = 'bearer '+localStorage.getItem("token") || ""
    }
    return config//必须返回出去，不然请求发不出去
}, error => {
    //处理错误请求
    return Promise.reject(error)
})
//响应拦截：后端返回来的结果
request.interceptors.response.use((res) => {
    const code: number = res.data.code//code是后端的状态码
    if (code !== 200) {
        //请求失败（包括token失效，302，404...根据和后端约定好的状态码做出不同的处理）
        if(code == 1003){
            localStorage.removeItem("token");
            ElMessage({
                message: res.data.message,
                type: 'error',
            })
            // 并且跳转到首页
            //Jump('/')
            return Promise.resolve(res.data)
        }else{
            //console.log(res.data)
            return Promise.reject(res.data)
        }

    } else {
        //请求成功
        //console.log(res, '成功----')
        return Promise.resolve(res.data)
    }
}, (err) => {
   // console.log(err, '错误信息的处理')//错误信息的处理
    //公共处理服务器响应出错
    ElMessage({
        message: err,
        type: 'error',
    })
    /*ElMessage({
        message: 'Lỗi hệ thống, vui lòng liên hệ với quản trị viên',
        type: 'error',
    })*/
    //处理错误响应
    return Promise.reject(err)
})
//因为别的地方要用，所以就把实例暴露出去，导出
export default request