<template>
  <el-config-provider :locale="locale">
    <!-- 全屏显示 -->
    <router-view :pc="pc" v-if="!$route.meta.show_site" />
  </el-config-provider>
</template>
<script>
import { ElConfigProvider } from "element-plus";
import zhCn from "element-plus/lib/locale/lang/zh-cn";
export default {
  components: {
    [ElConfigProvider]: ElConfigProvider,
  },
  data() {
    return {
      locale: zhCn, //加载语言
      pc: true, //pc状态，会向下传递，你可以通过props["pc"]获取，true时是pc访问，否则是手机
    };
  },
  methods: {
    isMobile() {
      //判断不同的访问设备和宽度实现菜单响应
      const window_width = document.documentElement.clientWidth; //获取窗口大小
      let flag = navigator.userAgent.match(
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      ); //获取访问navigator
      var vist_device = ""; //定义变量储存设备类型
      if (flag) {
        //判断是否是手机
        vist_device = "mobile";
      } else {
        vist_device = "pc";
      }
      if ((vist_device == "pc") & (window_width >= 768)) {
        this.pc = true;
      } else {
        this.pc = false;
      }
    },
  },
  mounted() {
    this.isMobile(); //调用访问设备判断方法
    window.onresize = () => {
      //窗口变化时调用
      this.isMobile();
    };
  },
};
</script>
<style scoped lang="scss">
/*:deep(.el-table tr){
  background-color: #162637;
}
:deep(.el-table th){
  background-color:#00000062;
}*/
.login-main-pc{
  background-image: url("/public/images/bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
</style>