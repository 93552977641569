//我要用到的一些接口
import request from "@/utils/request"

//获取用户信息
export function user_info() {
    return request({
        url: "/users/info",
        method: "GET",
    })
}

//退出登录
export function logout() {
    return request({
        url: "/users/logout",
        method: "GET",
    })
}

// 修改密码
export function forget(data: object) {
    return request({
        url: "/users/forget",
        method: "POST",
        data
    })
}

//绑定银行信息
export function bindBank(data: object) {
    return request({
        url: "/users/bindBank",
        method: "POST",
        data
    })
}

//绑定银行信息
export function getBindBank() {
    return request({
        url: "/users/bindBank",
        method: "GET",
    })
}

//充值
export function recharge(data: object) {
    return request({
        url: "/users/recharge",
        method: "POST",
        data
    })
}

//充值列表
export function rechargeList() {
    return request({
        url: "/users/recharge",
        method: "GET",
    })
}

//转移
export function transfer(data: object) {
    return request({
        url: "/users/transfer",
        method: "POST",
        data
    })
}

//转移列表
export function transferList() {
    return request({
        url: "/users/transfer",
        method: "GET",
    })
}

//提现
export function withdraw(data: object) {
    return request({
        url: "/users/withdraw",
        method: "POST",
        data
    })
}

//提现列表
export function withdrawList() {
    return request({
        url: "/users/withdraw",
        method: "GET",
    })
}

//消息
export function messagePost(data: object) {
    return request({
        url: "/users/message",
        method: "POST",
        data
    })
}

//消息列表
export function messageList() {
    return request({
        url: "/users/message",
        method: "GET",
    })
}