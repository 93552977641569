import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

//后置路由
router.afterEach((to, from) => {
    //console.log("全局后置路由守卫");
    //不需要登录地址配置
    const array = [
        '/login',
        '/register',
        '/policy',
        '/',
    ]
    //设置跳转路由后页面的标题
    const token = localStorage.getItem("token")
    /*if(token == null && array.indexOf(to.path) == -1){
        router.push('/login')
    }*/
    //需要验证登录的页面
   /* if(token == null && to.path.indexOf("/admin/") != -1 && to.path != '/admin/login'){
        router.push('/')
    }*/
    //check_token().then()
    document.title = to.meta.title as string
    //保存路由相关信息
    store.state.urlInfo = to
})


const app = createApp(App)
app.use(store)
app.use(router)
app.use(ElementPlus)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.mount('#app')

//createApp(App).use(store).use(router).use(ElementPlus).mount('#app')
